import Layout from 'components/layout';
import PageHeader from 'components/pageheader';
import { ProductsContainer } from 'components/product';
import Container from 'components/ui/container';
import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

export default function BlogListTemplate({
    data,
}: {
    data: GatsbyTypes.CategoryQuery;
}) {
    const products = data.products.nodes.map((item) => ({
        id: item.id,
        image: (item.frontmatter?.image?.childImageSharp
            ?.gatsbyImageData as unknown) as IGatsbyImageData,

        slug: item.fields?.slug as string,
        title: item.frontmatter?.title as string,
        category: item.frontmatter?.category as string,
    }));

    return (
        <Layout
            seo={{
                title: data.category?.frontmatter?.title as string,
                description: data.category?.frontmatter?.description as string,
                image: data.category?.frontmatter?.banner?.publicURL,
            }}
        >
            <PageHeader
                title={data.category?.frontmatter?.title as string}
                image={
                    (data.category?.frontmatter?.banner?.childImageSharp
                        ?.gatsbyImageData as unknown) as IGatsbyImageData
                }
                description={data.category?.frontmatter?.description}
            />
            <div className="py-16 px-3 products">
                <Container>
                    <ProductsContainer products={products} />
                </Container>
            </div>
        </Layout>
    );
}

export const query = graphql`
    query Category($locale: String!, $slug: String!, $name: String!) {
        category: mdx(fields: { slug: { eq: $slug } }) {
            frontmatter {
                title
                category
                description
                banner {
                    publicURL
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
        products: allMdx(
            filter: {
                fields: {
                    sourceName: { eq: "products" }
                    locale: { eq: $locale }
                }
                frontmatter: { category: { eq: $name } }
            }
        ) {
            nodes {
                id
                fields {
                    slug
                }
                frontmatter {
                    title
                    category
                    image {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    }
`;
